<template>
  <div class="w-full">
    <div v-if="templates.length > 0" class="grid grid-cols-12 gap-x-4">
      <div v-if="discountToGet > 0" class="font-semibold mb-4 col-span-12">
        {{ discountLine }}
      </div>
      <div
        v-for="tmp in templates"
        :key="tmp.code"
        class="col-span-12 xl:col-span-6"
      >
        <n-card
          @click.native="select(tmp)"
          class="product-card"
          use-border
          no-margin
        >
          <h5 class="text-xl font-semibold">
            {{ tmp.name }}
          </h5>
          <div class="py-4 border-b">
            <ul class="w-full border border-gray-200 rounded-lg">
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.theft"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.theft')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.abuse_call_costs"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.abuse-call-costs')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.loan_device"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.loan-device')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.data_recovery"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.data-recovery')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.display_damage"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.display-damage')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.water_damage"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.water-damage')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2 border-b"
                v-if="tmp.components && tmp.components.drop_damage"
              >
                <n-icon small name="Check"></n-icon>
                <n-html :text="$t('insurance.new.template.drop-damage')" />
              </li>
              <li
                class="flex flex-row gap-2 px-4 py-2"
                v-if="tmp.components && tmp.components.technical_malfunction"
              >
                <n-icon small name="Check"></n-icon>
                <n-html
                  :text="$t('insurance.new.template.technical-malfunction')"
                />
              </li>
            </ul>
          </div>
          <div class="w-full grid grid-cols-12 gap-2 my-4">
            <p class="col-span-8 md:col-span-5">
              <n-html :text="$t('insurance.new.template.monthly_amount')" />
            </p>
            <p class="col-span-4 md:col-span-7">€ {{ tmp.monthly_amount }}</p>
            <p class="col-span-8 md:col-span-5" v-if="tmp.excess_fee_theft > 0">
              <n-html :text="$t('insurance.new.template.excess_fee_theft')" />
            </p>
            <p class="col-span-4 md:col-span-7" v-if="tmp.excess_fee_theft > 0">
              € {{ tmp.excess_fee_theft }}
            </p>
            <p
              class="col-span-8 md:col-span-5"
              v-if="tmp.excess_fee_damage > 0"
            >
              <n-html :text="$t('insurance.new.template.excess_fee_damage')" />
            </p>
            <p
              class="col-span-4 md:col-span-7"
              v-if="tmp.excess_fee_damage > 0"
            >
              € {{ tmp.excess_fee_damage }}
            </p>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-12 lg:col-span-6">
              <a
                @click.stop="downloadPolicy(tmp)"
                class="underline text-brand-purple-500"
                ><n-html :text="$t('insurances.new.template.download-terms')"
              /></a>
            </div>
            <div class="col-span-12 lg:col-span-6">
              <a
                @click.stop="downloadIpid(tmp)"
                class="underline text-brand-purple-500"
                ><n-html :text="$t('insurances.new.template.download-ipid')"
              /></a>
            </div>
          </div>
        </n-card>
      </div>
    </div>
    <div v-else>
      <div class="mb-4">
        <n-html :text="$t('insurance.new.template.no-templates')" />
      </div>
      <div>
        <n-button-regular
          class="float-right margin-top-10"
          @click.native="select('OFFER')"
        >
          <n-html :text="$t('insurance.new.template.no-templates-continue')" />
        </n-button-regular>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadFile } from "../../../utils";

export default {
  name: "components.insurances.new.step-template",
  props: {
    category: Object,
    item: Object,
    manufacturer: Object,
    customerTemplate: String,
    contractAmount: Number,
    discountPercentage: Number
  },
  data: () => ({
    template: null
  }),
  computed: {
    ...mapGetters({
      templates: "insurance/getTemplates"
    }),
    discountToGet() {
      let discountPercentage = 0;
      if (this.contractAmount === 1) {
        discountPercentage = 10;
      }
      if (this.contractAmount === 2) {
        discountPercentage = 12.5;
      }
      if (this.contractAmount >= 3) {
        discountPercentage = 15;
      }

      return discountPercentage;
    },
    discountLine() {
      return this.$i18n
        .t("insurance.new.template.discount")
        .replace("{discount}", this.discountToGet);
    }
  },
  methods: {
    select(template) {
      this.template = template;
      if (this.template != null) {
        this.$parent.$parent.setCollapsed(true);
      }
    },
    downloadPolicy(tmp) {
      const baseString = tmp.terms_of_conditions;
      const filename = tmp.name + ".pdf";

      downloadFile(baseString, filename);
    },
    downloadIpid(tmp) {
      const baseString = tmp.ipid;
      const filename = tmp.name + " IPID.pdf";

      downloadFile(baseString, filename);
    }
  },
  watch: {
    template(n) {
      this.$parent.$parent.$emit("dataChange", n);
      this.$parent.$parent.$emit("pickedChange", n != null ? n.name : "");
    },
    item() {
      this.$store.dispatch("insurance/getTemplates", {
        retail_price: this.item.retail_price,
        item: this.item.item.code,
        manufacturer: this.manufacturer.code,
        template: this.customerTemplate,
        contract_amount: this.contractAmount
      });
    }
  },
  created() {
    if (this.templates && this.templates.length === 1) {
      this.selectDevice(this.templates[0]);
    }
  }
};
</script>

<style scoped>
.product-card {
  margin-bottom: 30px;
  cursor: pointer;
}
</style>
