<template>
  <div
    class="max-w-7xl m-auto grid grid-cols-12 grid-rows-1 pt-0 sm:pt-6 p-6 sm:p-8"
  >
    <div class="col-span-12 lg:col-span-6 sm:m-4 mt-6">
      <h1 class="uppercase text-brand-purple-500 font-serif font-bold text-3xl">
        <n-html :text="$t('insurance.new.title')" />
      </h1>
      <div v-if="accepted">
        <div class="mt-4">
          <p class="mb-4">
            <n-html :text="$t('insurance.new.success.message')" />
          </p>
          <n-button-regular
            @click.native="
              $router.push({ name: 'insurances.index' }).catch(() => {})
            "
          >
            <n-html :text="$t('insurance.new.success.button_to_contract')" />
          </n-button-regular>
        </div>
      </div>
    </div>
    <div class="col-span-12 sm:m-4 mt-6" v-if="!accepted">
      <card-step
        ref="step1"
        step="1"
        :title="$t('insurance.new.customer.title')"
        @dataChange="d => (this.step1 = d)"
      >
        <StepCustomer ref="step1" @confirm="c => (this.step1 = c)" />
      </card-step>
      <card-step
        ref="step2"
        step="2"
        :title="$t('insurance.new.category.title')"
        @dataChange="d => (this.step2 = d)"
      >
        <StepCategory
          ref="step2"
          :customer-template="step1.provided_by_template_no"
          :contract-amount="step1.contract_count"
          claim-type="damage"
          @dataChange="d => (this.step2 = d)"
        />
      </card-step>
      <card-step
        ref="step3"
        step="3"
        manual_close
        :title="$t('insurance.new.manufacturer.title')"
        @dataChange="d => (this.step3 = d)"
      >
        <StepManufacturer
          ref="step3"
          :customer-template="step1.provided_by_template_no"
          :contract-amount="step1.contract_count"
          :category="step2"
          @dataChange="d => (this.step3 = d)"
        />
      </card-step>
      <card-step
        ref="step4"
        step="4"
        manual_close
        :title="$t('insurance.new.item.title')"
        @dataChange="d => (this.step4 = d)"
      >
        <StepItem
          ref="step4"
          :customer-template="step1.provided_by_template_no"
          :contract-amount="step1.contract_count"
          :category="step2"
          :manufacturer="step3"
          @dataChange="d => (this.step4 = d)"
        />
      </card-step>
      <card-step
        ref="step5"
        step="5"
        :title="$t('insurance.new.templates.title')"
        @dataChange="d => (this.step5 = d)"
      >
        <StepTemplate
          ref="step5"
          :category="step2"
          :manufacturer="step3"
          :customer-template="step1.provided_by_template_no"
          :discount-percentage="step1.discount_percentage"
          :contract-amount="step1.contract_count"
          :item="step4"
          @dataChange="d => (this.step5 = d)"
        />
      </card-step>
      <card-step
        ref="step6"
        step="6"
        :title="$t('insurance.new.details.title')"
        @dataChange="d => (this.step6 = d)"
      >
        <StepItemDetails
          ref="step6"
          :customer="step1"
          :category="step2"
          :template="step5"
          @dataChange="d => (this.step6 = d)"
        />
      </card-step>
      <n-form-group
        id="button-group-claim-create"
        class="mt-4 text-center"
        :invalid-feedback="$t('insurance.new.create.error')"
        :state="form_states.form"
      >
        <n-button-regular
          class="sm:float-right"
          @click.native="submit"
          :disabled="!valid || form_states.loading"
        >
          <span v-if="form_states.loading"
            ><n-html :text="$t('insurance.new.button.sending')"
          /></span>
          <span v-else
            ><n-html :text="$t('insurance.new.button.request')"
          /></span>
        </n-button-regular>
      </n-form-group>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
// import { mapGetters } from "vuex";
import CardStep from "../../components/report/card-step";
import StepCategory from "../../components/insurances/new/step-category";
import StepManufacturer from "../../components/insurances/new/step-manufacturer";
import StepItem from "../../components/insurances/new/step-item";
import StepCustomer from "../../components/insurances/new/step-customer";
import StepTemplate from "../../components/insurances/new/step-template";
import StepItemDetails from "../../components/insurances/new/step-item-details";
import moment from "moment";

export default {
  name: "insurances.new",
  components: {
    CardStep,
    StepCategory,
    StepManufacturer,
    StepItem,
    StepCustomer,
    StepTemplate,
    StepItemDetails
  },
  data: () => ({
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    form_states: {
      form: null,
      loading: false
    },
    claim_number: null,
    routed: false,
    storeProvided: false,
    storeNotFound: true,
    accepted: false,
    rejected: false,
    selectedStore: null,
    storeOptions: [],
    storeLoading: false
  }),
  computed: {
    valid() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        !_.isEmpty(this.step5) &&
        !_.isEmpty(this.step6)
      );
    },
    showStep1() {
      return _.isEmpty(this.step1);
    },
    showStep2() {
      return !_.isEmpty(this.step1) && _.isEmpty(this.step2);
    },
    showStep3() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        _.isEmpty(this.step3)
      );
    },
    showStep4() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        _.isEmpty(this.step4)
      );
    },
    showStep5() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        _.isEmpty(this.step5)
      );
    },
    showStep6() {
      return (
        !_.isEmpty(this.step1) &&
        !_.isEmpty(this.step2) &&
        !_.isEmpty(this.step3) &&
        !_.isEmpty(this.step4) &&
        !_.isEmpty(this.step5) &&
        _.isEmpty(this.step6)
      );
    }
  },
  mounted() {
    this.$refs.step1.setCollapsed(false);
  },
  watch: {
    showStep1(n) {
      this.$refs.step1.setCollapsed(!n);
    },
    showStep2(n) {
      this.$refs.step2.setCollapsed(!n);
    },
    showStep3(n) {
      this.$refs.step3.setCollapsed(!n);
    },
    showStep4(n) {
      this.$refs.step4.setCollapsed(!n);
    },
    showStep5(n) {
      this.$refs.step5.setCollapsed(!n);
    },
    showStep6(n) {
      this.$refs.step6.setCollapsed(!n);
    }
  },
  methods: {
    submit() {
      this.form_states.loading = true;
      let country = "nl";
      if (
        this.step1.locale_code === "nl_BE" ||
        this.step1.locale_code === "fr_BE"
      ) {
        country = "be";
      }
      let payload = {
        template: this.step1.provided_by_template_no,
        contract_amount: this.step1.contract_count,
        customer: {
          gender: this.step1.gender,
          initials: this.step1.initials,
          first_name: this.step1.first_name,
          last_name: this.step1.surname,
          date_of_birth: this.step1.birth_date,
          address_line_1: this.step1.street_name,
          address_line_2:
            this.step1.street_number + this.step1.street_number_addition,
          zip_code: this.step1.zip_code,
          city: this.step1.city,
          country_code: country, // TODO: Fix
          phone_number: this.step1.phone,
          email_address: this.step1.email,
          iban_code: this.step1.iban
        },
        insurance: {
          start_date: moment().format("YYYY-MM-DD"), // TODO: Fix
          template_code: this.step5.code
        },
        device: {
          manufacturer: this.step3.code,
          model: this.step4.model,
          item_category_code: this.step2.code,
          item_no: this.step4.item.code,
          retail_price: parseFloat(this.step4.retail_price),
          imei_no: this.step6.imei ? this.step6.imei : "",
          serial_no: this.step6.serialnumber,
          purchase_date: this.step6.date,
          proof_of_purchase: this.step6.proof_of_purchase,
          device_images: this.step6.device_images
        }
      };

      this.$store
        .dispatch("insurance/postContract", payload)
        .then(() => {
          this.accepted = true;
          this.form_states.loading = false;
        })
        .catch(() => {
          this.form_states.request = false;
          this.form_states.loading = false;
        });
    }
  }
};
</script>
