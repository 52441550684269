<template>
  <div class="w-full">
    <div class="pb-4">
      <n-form-input-default
        :placeholder="$t('insurance.new.item.filter')"
        v-model="filter"
        type="search"
      />
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div
        v-for="mf in manufacturers.filter(x =>
          x.name.toLowerCase().includes(this.filter.toLowerCase())
        )"
        :key="mf.code"
        class="col-span-12 md:col-span-6 lg:col-span-3"
      >
        <n-card
          @click.native="selectDevice(mf)"
          class="cursor-pointer"
          no-margin
          use-border
        >
          <h5 class="text-xl font-semibold">
            {{ mf.name }}
          </h5>
        </n-card>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  name: "components.insurances.new.step-manufacturer",
  props: {
    category: Object,
    customerTemplate: String,
    contractAmount: Number
  },
  data: () => ({
    manufacturers: [
      { code: "ACER", name: "Acer" },
      { code: "ALIENWARE", name: "Alienware" },
      { code: "APPLE", name: "Apple" },
      { code: "ASUS", name: "Asus" },
      { code: "BTO", name: "Bto" },
      { code: "CHUWI", name: "Chuwi" },
      { code: "DELL", name: "Dell" },
      { code: "DYNABOOK", name: "Dynabook" },
      { code: "HP", name: "Hp" },
      { code: "HUAWEI", name: "Huawei" },
      { code: "ILABO", name: "iLabo" },
      { code: "LENOVO", name: "Lenovo" },
      { code: "MEDION", name: "Medion" },
      { code: "MICROSOFT", name: "Microsoft" },
      { code: "MSI", name: "Msi" },
      { code: "PARADIGIT", name: "Paradigit" },
      { code: "RAZER", name: "Razer" },
      { code: "SAMSUNG", name: "Samsung" },
      { code: "SES REFURB", name: "Ses refurb" },
      { code: "TERRA COMP", name: "Terra Computers" },
      { code: "TOSHIBA", name: "Toshiba" }
    ],
    manufacturer: null,
    filter: ""
  }),
  // computed: {
  //   ...mapGetters({
  //     manufacturers: "insurance/getManufacturers"
  //   })
  // },
  methods: {
    selectDevice(manufacturer) {
      this.manufacturer = manufacturer;
      if (this.manufacturer != null) {
        this.$parent.$parent.setCollapsed(true);
      }
    }
  },
  watch: {
    manufacturer(n) {
      this.$parent.$parent.$emit("dataChange", n);
      this.$parent.$parent.$emit("pickedChange", n != null ? n.name : "");
    },
    category() {
      if (this.category.code) {
        this.$store.dispatch("insurance/getManufacturers", {
          item_category: this.category.code,
          template: this.customerTemplate,
          contract_amount: this.contractAmount
        });
      }
    }
  },
  created() {
    if (this.manufacturers && this.manufacturers.length === 1) {
      this.selectDevice(this.manufacturers[0]);
    }
  }
};
</script>

<style scoped></style>
