<template>
  <div class="w-full">
    <div v-if="category.code !== 'PHONE'" class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-4">
        <p
          class="
          "
        >
          <n-html :text="$t('insurance.new.item-details.serialnumber.label')" />
        </p>
      </div>
      <div class="col-span-12 md:col-span-8">
        <n-form-group>
          <n-form-input-default
            :placeholder="
              $t('insurance.new.item-details.serialnumber.placeholder')
            "
            v-model="form.serialnumber"
          />
        </n-form-group>
      </div>
    </div>
    <div v-else class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-4">
        <p>
          <n-html :text="$t('insurance.new.item-details.imei.label')" />
        </p>
      </div>
      <div class="col-span-12 md:col-span-8">
        <n-form-group>
          <n-form-input-default
            :placeholder="$t('insurance.new.item-details.imei.placeholder')"
            v-model="imei"
            field_type="text"
            maxlength="15"
            :state="this.errors.imei"
          />
        </n-form-group>
      </div>
    </div>
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-4">
        <p>
          <n-html
            :text="$t('insurance.new.item-details.date-of-purchase.label')"
          />
        </p>
      </div>
      <div class="col-span-12 md:col-span-8">
        <n-form-group>
          <n-form-input-default
            id="input-date"
            :minDate="minDate"
            :maxDate="maxDate"
            :placeholder="
              $t('insurance.new.item-details.date-of-purchase.placeholder')
            "
            v-model="form.date"
            :auto-apply="true"
            field_type="date"
          />
        </n-form-group>
      </div>
    </div>
    <!--    <b-row>-->
    <!--      <b-col cols="12" sm="12" md="4" lg="4" xl="4">-->
    <!--        <p-->
    <!--            class="typography-p-regular margin-bottom-none margin-mobile-bottom-grid-between"-->
    <!--        >-->
    <!--          <n-html :text='$t("insurance.new.item-details.start-date.label")' />-->
    <!--        </p>-->
    <!--      </b-col>-->
    <!--      <b-col cols="12" sm="12" md="8" lg="8" xl="8">-->
    <!--        <n-form-group class="margin-top-10 margin-bottom-none">-->
    <!--          {{today}}-->
    <!--          <n-form-input-default-->
    <!--              id="start-date"-->
    <!--              :minDate="maxDate"-->
    <!--              :placeholder="-->
    <!--              $t('insurance.new.item-details.start-date.placeholder')-->
    <!--            "-->
    <!--              v-model="form.start_date"-->
    <!--              field_type="date"-->
    <!--          />-->
    <!--        </n-form-group>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <div class="border-b mb-4">
      <insurance-image-upload @imageSelected="d => this.onImageUpload(d)" />
    </div>
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-12">
        <n-form-group class="margin-top-10 margin-bottom-none">
          <n-checkbox
            v-model="form.permission_iban"
            :label="$t('insurance.new.item-details.iban-permission')"
          />
        </n-form-group>
      </div>
    </div>
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-12">
        <n-form-group class="margin-top-10 margin-bottom-none">
          <n-checkbox
            v-model="form.terms_agreement"
            name="checkbox"
            class="checkbox-regular"
            :class="[form.terms_agreement ? 'checkbox-checked' : '']"
          >
            <span>
              <n-html :text="$t('insurance.new.item-details.terms-prefix')" />
              <a
                class="underline text-brand-purple-500 cursor-pointer"
                @click.self.prevent="downloadPolicy"
                ><n-html :text="$t('insurance.new.item-details.terms-link')"
              /></a>
              <n-html :text="$t('insurance.new.item-details.terms-suffix')" />
            </span>
          </n-checkbox>
        </n-form-group>
      </div>
    </div>
    <div>
      <n-button-regular
        class="float-right margin-top-10"
        @click.native="confirm"
        :disabled="!isValid"
      >
        <n-html :text="$t('insurance.new.item_details.confirm')" />
      </n-button-regular>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import InsuranceImageUpload from "../insurance-image-upload.vue";
import { downloadFile } from "../../../utils";

export default {
  name: "components.insurances.new.step-item-details",
  components: { InsuranceImageUpload },
  data: () => ({
    imei: null,
    form: {
      serialnumber: "",
      date: "",
      imei: "",
      proof_of_purchase: "",
      device_images: [],
      permission_iban: false,
      terms_agreement: false
    },
    errors: {
      imei: null
    }
  }),
  props: {
    category: Object,
    template: Object,
    customer: Object
  },
  computed: {
    minDate() {
      return moment()
        .subtract(1, "years")
        .toDate();
    },
    maxDate() {
      return moment().toDate();
    },
    isValid() {
      let validImeiOrSerial = false;
      if (this.category.code === "PHONE") {
        validImeiOrSerial = this.validIMEI(this.form.imei);
      } else {
        validImeiOrSerial = !!this.form.serialnumber;
      }
      return (
        validImeiOrSerial &&
        this.form.date &&
        // this.form.start_date &&
        this.form.permission_iban &&
        // this.form.iban &&
        this.form.terms_agreement
      );
    }
  },
  mounted() {
    // TODO: Auto fill iban number
  },
  methods: {
    confirm() {
      this.$parent.$parent.$emit("pickedChange", this.form.date);
      this.$parent.$parent.$emit("dataChange", this.form);
      this.$parent.$parent.setCollapsed(true);
    },
    isInt(n) {
      return n % 1 === 0;
    },
    validIMEI(imei) {
      imei = imei.split("");
      let total = 0;

      if (imei.length !== 15) {
        return false;
      }

      for (let i = 0; i < imei.length; i++) {
        if (i % 2 === 0) {
          // If even number
          total += parseInt(imei[i]);
          continue;
        }

        let calculated = parseInt(imei[i]) * 2;

        if (calculated > 9) {
          // If greater than 9
          let splitted = String(calculated).split("");
          total += parseInt(splitted[0]) + parseInt(splitted[1]);
        } else {
          total += calculated;
        }
      }
      this.errors.imei = !!this.isInt(total / 10);
      return !!this.isInt(total / 10);
    },
    imageToBase64(file, type, index) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (type === "receipt") {
          this.form.proof_of_purchase = reader.result;
        } else if (type === "device") {
          this.form.device_images[index] = reader.result;
        }
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    },
    async onImageUpload(data) {
      if (data.receipt_pictures.length > 0) {
        this.imageToBase64(data.receipt_pictures[0], "receipt", 0);
      }

      if (data.device_pictures.length > 0) {
        data.device_pictures.forEach(async (picture, index) => {
          this.imageToBase64(picture, "device", index);
        });
      }
    },
    downloadPolicy() {
      const baseString = this.template.terms_of_conditions;
      const filename = this.template.name + ".pdf";

      downloadFile(baseString, filename);
    }
  },
  watch: {
    imei(a) {
      this.validIMEI(a);
      this.form.imei = a;
    }
  }
};
</script>
