<template>
  <div class="w-full">
    <div class="border-b pb-2">
      <h4 class="text-lg text-brand-purple-500">
        <n-html :text="$t('details.personal.forms.personal')" />
      </h4>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.title.label')" />
        </p>
        <p>
          {{ customer.title }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.initials.label')" />
        </p>
        <p>
          {{ customer.initials }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-4">
        <p class="font-semibold">
          <n-html :text="$t('form.first_name.label')" />
        </p>
        <p>
          {{ customer.first_name }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-4">
        <p class="font-semibold">
          <n-html :text="$t('form.middle_name.label')" />
        </p>
        <p>
          {{ customer.middle_name !== "" ? customer.middle_name : "-" }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-4">
        <p class="font-semibold">
          <n-html :text="$t('form.last_name.label')" />
        </p>
        <p>
          {{ customer.surname }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.birth_date.label')" />
        </p>
        <p class="typography-p-regular margin-bottom-none">
          {{ this.format_date(customer.birth_date, "DD-MM-YYYY") }}
        </p>
      </div>
    </div>
    <div class="border-b py-2">
      <h4 class="text-lg text-brand-purple-500 mt-10">
        <n-html :text="$t('details.personal.forms.address')" />
      </h4>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.zip_code.label')" />
        </p>
        <p>
          {{ customer.zip_code }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.street_number.label')" />
        </p>
        <p>
          {{ customer.street_number }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.street_number_addition.label')" />
        </p>
        <p>
          {{ customer.street_number_addition }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.street_name.label')" />
        </p>
        <p>
          {{ customer.street_name }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.city.label')" />
        </p>
        <p class="typography-p-regular margin-bottom-none">
          {{ customer.city }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.email.label')" />
        </p>
        <p>
          {{ customer.email }}
        </p>
      </div>
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.phone.label')" />
        </p>
        <p>
          {{ customer.phone }}
        </p>
      </div>
    </div>
    <div class="grid grid-cols-12 border-b py-4">
      <div class="col-span-12 md:col-span-6">
        <p class="font-semibold">
          <n-html :text="$t('form.iban.label')" />
        </p>
        <p>
          {{ customer.iban }}
        </p>
      </div>
    </div>
    <div class="py-4">
      <p>
        <n-html :text="$t('insurance.new.customer.confirm-personal-details')" />
      </p>
    </div>
    <div class="grid grid-cols-12 pb-4 gap-4">
      <div class="col-span-12 sm:col-span-6">
        <n-button-regular @click.native="confirm">
          <n-html :text="$t('insurance.new.customer.confirm')" />
        </n-button-regular>
      </div>
      <div class="col-span-12 sm:col-span-6">
        <n-button-regular secondary @click.native="cancel">
          <n-html :text="$t('insurance.new.customer.change-details')" />
        </n-button-regular>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "components.insurances.new.step-customer",
  props: {
    claimType: String
  },
  data: () => ({
    _customer: null
  }),
  computed: {
    ...mapGetters({
      customer: "customer/get"
    })
  },
  methods: {
    confirm() {
      this._customer = this.customer;
      this.$emit("confirm", this.customer);
      if (this._customer != null) {
        this.$parent.$parent.setCollapsed(true);
      }
      this.$parent.$parent.$emit(
        "pickedChange",
        this.$t("insurance.new.customer.confirmed")
      );
    },
    cancel() {
      this.$router.push({
        name: "details.personal"
      });
    }
  }
};
</script>
